<template>
    <div v-if="!model.is_overall">
        <div class="row_select" v-for="item in old_emp_list" :key="item">
            <div>{{ item.chs_name }}{{ item.mobile_phone }}</div>
            <el-button type="danger" link icon="Remove" size="large" @click="onOldRemove(index)"></el-button>
        </div>
        <div v-for="(item, index) in emp_selected_list" :key="item" class="row_select">
            <el-select v-model="item.id" placeholder="" style="width:150px;" @change="selectChange">
                <el-option v-for="emp in emp_list" :key="emp.id" :value="emp.id"
                    :label="`${emp.chs_name}${emp.mobile_phone}`"></el-option>
            </el-select>
            <el-button type="danger" link icon="Remove" size="large" @click="onRemove(index)"></el-button>
        </div>
        <div>
            <el-button type="primary" link icon="CirclePlus" size="large" @click="onAdd"></el-button>
        </div>
    </div>
    <div v-else>
        <div v-for="emp in emp_list" :key="emp">{{ emp.chs_name }}{{ emp.mobile_phone }}</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            //旧的
            old_emp_list: [],
            //所有的岗位人员
            emp_list: [],
            //已选择的
            emp_selected_list: [],
        }
    },
    props: ['model', 'managers'],
    emits: ['change'],
    watch: {
        model: {
            handler: function () {
                if (this.model) {
                    if (!this.model.is_overall && this.managers) {
                        var m = this.managers.find(x => x.post_id == this.model.post_id);
                        if (m) {
                            this.old_emp_list = m.emp_list;
                        }
                        this.selectChange();
                    }
                    this.loadData();
                }
            },
            immediate: true,
        }
    },
    methods: {
        /**
         * 加载人员数据
         */
        loadData() {
            this.$http.get('seller/v1/common/post_emp?postId=' + this.model.post_id).then(res => {
                if (res.code == 0) {
                    this.emp_list = res.data;
                }
            })
        },

        /**
         * 新增行
         */
        onAdd() {
            this.emp_selected_list.push({
                id: ''
            })
        },
        /**
         * 移除新增行
         * @param {*} i 
         */
        onRemove(i) {
            this.emp_selected_list.splice(i, 1);
            this.selectChange();
        },
        /**
         * 移除旧数据
         * @param {*} i 
         */
        onOldRemove(i) {
            this.old_emp_list.splice(i, 1);
            this.selectChange();
        },
        /**
         * 选择更改
         */
        selectChange() {
            var list = this.emp_selected_list.filter(x => x.id);
            if (this.old_emp_list) {
                this.old_emp_list.forEach(x => {
                    list.push({
                        id:x.id,
                    })
                });
            }
            this.$emit('change', {
                post_id: this.model.post_id,
                emp_list: list,
            })
        }
    },
}
</script>

<style scoped>
.row_select {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
</style>